
import { defineComponent } from "vue";

import GapCatalogue from "@/components/catalogues/GapCatalogue/GapCatalogue.vue";

export default defineComponent({
  components: { GapCatalogue },
  props: {},
  methods: {},
  data() {
    return {};
  },
});
